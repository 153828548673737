import React, { useEffect, useState } from "react";
import WebRadioPlayer from "./components/WebRadioPlayer";
import "./App.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import CookieConsentBanner from './components/CookieConsentBanner';
import PolitiqueConfidentialite from './components/PolitiqueConfidentialite';
import Hero from './components/Hero';
import Footer from './components/Footer';
// import DynamicSongBar from './components/DynamicSongBar';
import { SocialLinks } from './components/Footer';

function AnimatedBackground() {
    return (
        <div className="background">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
        </div>
    );
}

function App() {
    const [selectedStation, setSelectedStation] = useState(null);
    const [playingStation, setPlayingStation] = useState(null);
    const [hasConsented, setHasConsented] = useState(null);

    // Données des stations en dur
    const [radios, setRadios] = useState([
        { id: 1, name: "Starz 00s", logo: `${process.env.PUBLIC_URL}/logos/00s.webp`, stream: "https://audio.starzwebradio.com/stream/21/", historyUrl: "https://audio.starzwebradio.com/played.html?sid=21" },
        { id: 2, name: "Starz 60s", logo: `${process.env.PUBLIC_URL}/logos/60s.webp`, stream: "https://audio.starzwebradio.com/stream/13/", historyUrl: "https://audio.starzwebradio.com/played.html?sid=13" },
        { id: 3, name: "Starz 70s", logo: `${process.env.PUBLIC_URL}/logos/70s.webp`, stream: "https://audio.starzwebradio.com/stream/15/", historyUrl: "https://audio.starzwebradio.com/played.html?sid=15" },
        { id: 4, name: "Starz 80s", logo: `${process.env.PUBLIC_URL}/logos/80s.webp`, stream: "https://audio.starzwebradio.com/stream/2/", historyUrl: "https://audio.starzwebradio.com/played.html?sid=2" },
        { id: 5, name: "Starz 90s", logo: `${process.env.PUBLIC_URL}/logos/90s.webp`, stream: "https://audio.starzwebradio.com/stream/9/", historyUrl: "https://audio.starzwebradio.com/played.html?sid=9" },
        { id: 6, name: "Starz Blues", logo: `${process.env.PUBLIC_URL}/logos/blues.webp`, stream: "https://audio.starzwebradio.com/stream/4/", historyUrl: "https://audio.starzwebradio.com/played.html?sid=4" },
        { id: 7, name: "Starz Christmas", logo: `${process.env.PUBLIC_URL}/logos/christmas.webp`, stream: "https://audio.starzwebradio.com/stream/12/", historyUrl: "https://audio.starzwebradio.com/played.html?sid=12" },
        { id: 8, name: "Starz Classical", logo: `${process.env.PUBLIC_URL}/logos/classical.webp`, stream: "https://audio.starzwebradio.com/stream/17/", historyUrl: "https://audio.starzwebradio.com/played.html?sid=17" },
        { id: 9, name: "Starz Country", logo: `${process.env.PUBLIC_URL}/logos/country.webp`, stream: "https://audio.starzwebradio.com/stream/16/", historyUrl: "https://audio.starzwebradio.com/played.html?sid=16" },
        { id: 10, name: "Starz Dance", logo: `${process.env.PUBLIC_URL}/logos/dance.webp`, stream: "https://audio.starzwebradio.com/stream/7/", historyUrl: "https://audio.starzwebradio.com/played.html?sid=7" },
        { id: 11, name: "Starz Funk", logo: `${process.env.PUBLIC_URL}/logos/funk.webp`, stream: "https://audio.starzwebradio.com/stream/11/", historyUrl: "https://audio.starzwebradio.com/played.html?sid=11" },
        { id: 12, name: "Starz Italia", logo: `${process.env.PUBLIC_URL}/logos/italia.webp`, stream: "https://audio.starzwebradio.com/stream/19/", historyUrl: "https://audio.starzwebradio.com/played.html?sid=19" },
        { id: 13, name: "Starz Jazz", logo: `${process.env.PUBLIC_URL}/logos/jazz.webp`, stream: "https://audio.starzwebradio.com/stream/5/", historyUrl: "https://audio.starzwebradio.com/played.html?sid=5" },
        { id: 14, name: "Starz K-pop", logo: `${process.env.PUBLIC_URL}/logos/kpop.webp`, stream: "https://audio.starzwebradio.com/stream/18/", historyUrl: "https://audio.starzwebradio.com/played.html?sid=18" },
        { id: 15, name: "Starz Latino", logo: `${process.env.PUBLIC_URL}/logos/latino.webp`, stream: "https://audio.starzwebradio.com/stream/20/", historyUrl: "https://audio.starzwebradio.com/played.html?sid=20" },
        { id: 16, name: "Starz Lounge", logo: `${process.env.PUBLIC_URL}/logos/lounge.webp`, stream: "https://audio.starzwebradio.com/stream/3/", historyUrl: "https://audio.starzwebradio.com/played.html?sid=3" },
        { id: 17, name: "Starz Love", logo: `${process.env.PUBLIC_URL}/logos/love.webp`, stream: "https://audio.starzwebradio.com/stream/14/", historyUrl: "https://audio.starzwebradio.com/played.html?sid=14" },
        { id: 18, name: "Starz New Hits", logo: `${process.env.PUBLIC_URL}/logos/newhits.webp`, stream: "https://audio.starzwebradio.com/stream/6/", historyUrl: "https://audio.starzwebradio.com/played.html?sid=6" },
        { id: 19, name: "Starz Rap", logo: `${process.env.PUBLIC_URL}/logos/rap.webp`, stream: "https://audio.starzwebradio.com/stream/10/", historyUrl: "https://audio.starzwebradio.com/played.html?sid=10" },
        { id: 20, name: "Starz RnB", logo: `${process.env.PUBLIC_URL}/logos/rnb.webp`, stream: "https://audio.starzwebradio.com/stream/8/", historyUrl: "https://audio.starzwebradio.com/played.html?sid=8" },
    ]);

    // Fonction pour récupérer les titres en cours des autres stations
    useEffect(() => {
        const fetchCurrentSongsForStations = async () => {
            const updatedRadios = await Promise.all(
                radios.map(async (radio) => {
                    try {
                        const sid = radio.historyUrl.split("sid=")[1];
                        const url = `https://audio.starzwebradio.com/stats?sid=${sid}`;
                        const cacheBuster = new Date().getTime();

                        const response = await fetch(`${url}?cacheBuster=${cacheBuster}`);
                        const data = await response.text();
                        const parser = new DOMParser();
                        const xmlDoc = parser.parseFromString(data, "text/xml");

                        const songTitle = xmlDoc.getElementsByTagName("SONGTITLE")[0]?.textContent || "Titre non disponible";
                        const [artist, title] = songTitle.split(" - ");

                        const fetchSpotifyImage = async (artist) => {
                            try {
                                const clientId = "ebabe7602d634ebeb50f092f72660c55";
                                const clientSecret = "f0b362f99e6244538a584f92af504488";

                                const tokenResponse = await fetch("https://accounts.spotify.com/api/token", {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/x-www-form-urlencoded",
                                        "Authorization": `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
                                    },
                                    body: "grant_type=client_credentials",
                                });

                                const tokenData = await tokenResponse.json();
                                const accessToken = tokenData.access_token;

                                const artistResponse = await fetch(`https://api.spotify.com/v1/search?q=${encodeURIComponent(artist)}&type=artist`, {
                                    headers: {
                                        "Authorization": `Bearer ${accessToken}`,
                                    },
                                });

                                const artistData = await artistResponse.json();
                                const artistInfo = artistData.artists.items[0];

                                if (artistInfo && artistInfo.images.length > 0) {
                                    return artistInfo.images[0].url;
                                }
                            } catch (err) {
                                console.error("Erreur lors de la récupération de l'image Spotify :", err);
                            }
                            return radio.logo; // Retourne le logo par défaut en cas d'erreur
                        };

                        const albumCover = artist ? await fetchSpotifyImage(artist) : radio.logo;

                        return {
                            ...radio,
                            currentArtist: artist || "Artiste inconnu",
                            currentTitle: title || "Titre inconnu",
                            albumCover,
                        };
                    } catch (err) {
                        console.error(`Erreur pour la station ${radio.name}:`, err);
                        return {
                            ...radio,
                            currentArtist: "Artiste inconnu",
                            currentTitle: "Titre non disponible",
                            albumCover: radio.logo,
                        };
                    }
                })
            );
            setRadios((prevRadios) =>
                prevRadios.map((radio) =>
                    updatedRadios.find((updatedRadio) => updatedRadio.id === radio.id) || radio
                )
            );
        };

        fetchCurrentSongsForStations();
        const interval = setInterval(fetchCurrentSongsForStations, 30000);
        return () => clearInterval(interval);
    }, []); // Dépendances vidées pour éviter les réinitialisations

    // Fonction pour jouer une station
    const playStation = (station) => {
        console.log(`Lecture de la station : ${station.name}`);
        // Ajoute ici ton code pour démarrer réellement la lecture, par exemple avec un lecteur audio HTML5
    };

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (consent) {
            setHasConsented(JSON.parse(consent));
        }
    }, []);

    const handleConsent = (consentGiven) => {
        setHasConsented(consentGiven);
        localStorage.setItem('cookieConsent', JSON.stringify(consentGiven));
    };

    const handleStationChange = (station) => {
        setSelectedStation(station);
        setPlayingStation(station.name);
    };

    const appStyles = {
        minHeight: '100vh',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    return (
        <Router>
            <div style={appStyles} className="App">
                <AnimatedBackground />
                <>
                    {window.location.pathname === '/' && <Hero />}
                    <Routes>
                        <Route path="/" element={<Home
                            selectedStation={selectedStation}
                            setSelectedStation={setSelectedStation}
                            radios={radios}
                            playingStation={playingStation}
                            setPlayingStation={setPlayingStation}
                            handleStationChange={handleStationChange}
                        />} />
                        <Route path="/politique-confidentialite" element={<PolitiqueConfidentialite />} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                    {hasConsented === null && <CookieConsentBanner onConsent={handleConsent} />}
                </>
            </div>
        </Router>
    );
}

function Home({ selectedStation, setSelectedStation, radios, setPlayingStation }) {
    const [randomStations, setRandomStations] = useState([]);
    const [maxCards, setMaxCards] = useState(8); // Nombre de cartes par défaut

    // Fonction pour gérer le nombre de cartes affichées en fonction de l'écran
    useEffect(() => {
        const updateMaxCards = () => {
            const width = window.innerWidth;
            if (width < 576) {
                setMaxCards(2); // Mobile : 2 cartes
            } else if (width < 768) {
                setMaxCards(4); // Tablette : 4 cartes
            } else if (width < 1024) {
                setMaxCards(6); // Petits écrans : 6 cartes
            } else {
                setMaxCards(8); // Écrans larges : 8 cartes
            }
        };

        updateMaxCards(); // Définir la valeur initiale
        window.addEventListener("resize", updateMaxCards); // Ajouter un listener pour les changements de taille
        return () => window.removeEventListener("resize", updateMaxCards); // Nettoyage
    }, []);

    // Fonction pour gérer les stations aléatoires
    useEffect(() => {
        const getRandomStations = () => {
            const shuffledStations = [...radios].sort(() => Math.random() - 0.5);
            setRandomStations(shuffledStations.slice(0, maxCards)); // Utilise `maxCards` pour limiter
        };

        getRandomStations();
        const interval = setInterval(getRandomStations, 45000); // Met à jour toutes les 45 secondes
        return () => clearInterval(interval); // Nettoyage
    }, [radios, maxCards]);

    const handleStationPlay = (radio) => {
        setSelectedStation(radio); // Définit la station sélectionnée
        setPlayingStation(radio.name); // Met à jour l'état "station jouée"
    };

    const mainSectionStyles = {
        width: '100%',
        minHeight: '100vh',
        padding: '0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '20px',
    };

    return (
        <>
            <section style={mainSectionStyles} id="radios">
                <div className="title-container">
                    <h2>Choisissez Votre Station</h2>
                </div>
                <div className="carousel-container">
                    <div className="radio-carousel">
                        {radios.map((radio) => (
                            <div
                                key={radio.name}
                                className={`radio-item ${selectedStation?.name === radio.name ? 'active' : ''}`}
                                onClick={() => setSelectedStation(radio)}
                            >
                                <img
                                    src={radio.logo}
                                    alt={`${radio.name} logo`}
                                    style={{
                                        width: "150px",
                                        height: "150px",
                                        borderRadius: "15px",
                                        objectFit: "cover",
                                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="hero-social-links">
                    <SocialLinks />
                </div>
                <WebRadioPlayer
                    selectedStation={selectedStation}
                    setSelectedStation={setSelectedStation}
                    setPlayingStation={setPlayingStation}
                />
                <div className="title-container-cards">
                    <h2>Sur les autres stations</h2>
                </div>

                <div id="other-stations-cards">
                    {randomStations.map((radio, index) => (
                        <div key={index} className="station-card">
                            <img
                                src={radio.albumCover || radio.logo}
                                alt={`${radio.name} cover`}
                                className="station-card-logo"
                            />
                            <div className="card-time">{radio.name}</div>
                            <div className="card-artist">{radio.currentArtist || "Artiste inconnu"}</div>
                            <div className="card-title">{radio.currentTitle || "Titre inconnu"}</div>
                            <div className="play-button-container">
                                <div
                                    className="circle-button play"
                                    onClick={() => handleStationPlay(radio)}
                                >
                                    <i className="bi bi-play-fill"></i>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <section className="footer-section">
                <Footer />
            </section>
        </>
    );
}

export default App;