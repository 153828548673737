import React from 'react';

function PolitiqueConfidentialite() {
    return (
        <div style={{ color: 'white', padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
            <h1>Politique de Confidentialité de Starz Webradio</h1>

            <p>Bienvenue sur Starz Webradio ! Nous respectons votre vie privée et nous nous engageons à protéger vos données personnelles. Voici comment nous gérons vos informations lorsque vous visitez notre site ou écoutez nos stations.</p>

            <h2>1. Données collectées</h2>
            <p>Starz Webradio ne collecte pas directement de données personnelles lorsque vous naviguez ou écoutez nos stations.</p>
            <p>Cependant, pour des raisons techniques, des informations telles que l'adresse IP et la durée d'écoute peuvent être temporairement enregistrées de manière anonyme pour assurer le bon fonctionnement du service de diffusion.</p>
            <p>Ces données ne sont ni exploitées, ni conservées par Starz Webradio, et sont uniquement utilisées temporairement pour assurer le bon fonctionnement du service de diffusion.</p>

            <h2>2. Utilisation des cookies</h2>
            <p>Lors de votre première visite, un bandeau de consentement s’affiche pour vous informer de l’utilisation des cookies. Ces cookies permettent :</p>
            <ul>
                <li>De mémoriser votre consentement pour ne plus afficher le bandeau à chaque visite.</li>
                <li>D’améliorer votre expérience utilisateur en rendant la navigation plus fluide.</li>
            </ul>
            <p>Aucune information personnelle n’est enregistrée ou exploitée via ces cookies.</p>

            <h2>3. Partage des données</h2>
            <p>Starz Webradio ne partage aucune donnée personnelle avec des tiers. Les informations techniques éventuellement collectées pour la diffusion ne sont ni exploitées, ni partagées.</p>

            <h2>4. Vos droits</h2>
            <p>Comme nous ne collectons pas directement de données personnelles, vous n'avez aucune action à entreprendre concernant vos informations.</p>

            <h2>5. Sécurité</h2>
            <p>Nous mettons tout en œuvre pour assurer la sécurité et la confidentialité de votre navigation sur Starz Webradio. Le service de diffusion est géré de manière sécurisée pour garantir une expérience fiable et protégée.</p>
        </div>
    );
}

export default PolitiqueConfidentialite;