import React, { useState, useEffect } from 'react';
import './Hero.css';

function Hero() {
    const [heroImage, setHeroImage] = useState(null);

    // Données statiques pour les catégories
    const categories = [
        { id: 1, name: 'Permanentes', start_date: '01-05', end_date: '12-01' },
        { id: 2, name: 'Halloween', start_date: '10-10', end_date: '11-02' },
        { id: 3, name: 'Noel', start_date: '12-01', end_date: '12-26' },
        { id: 4, name: 'NouvelAn', start_date: '12-26', end_date: '01-05' },
    ];

    // Images associées aux catégories
    const heroImages = {
        Permanentes: [
            'permanentes001.webp',
            'rnb.webp',
            'reggae.webp',
            'rap.webp',
            'newhits.webp',
            'love.webp',
            'lounge.webp',
            'latino.webp',
            'kpop.webp',
            'jazz.webp',
            'italia.webp',
            'funk.webp',
            'dance.webp',
            'permanentes002.webp',
            'classical.webp',
            'christmas.webp',
            'permanentes003.webp',
            'permanentes004.webp',
            'permanentes005.webp',
            'permanentes006.webp',
            'permanentes007.webp',
            'permanentes008.webp',
            'permanentes009.webp',
            'blues.webp',
            'permanentes010.webp',
            'permanentes011.webp',
            '90s.webp',
            '80s.webp',
            '70s.webp',
            '60s.webp',
            '10s.webp',
            '00s.webp'
        ],
        Halloween: [
            'chuckysw.webp',
            'chuckysw2.webp',
            'chuckysw3.webp',
            'creaturesw.webp',
            'draculasw.webp',
            'frankensteinsw.webp',
            'freddysw.webp',
            'freddysw2.webp',
            'freddysw3.webp',
            'ghostfacesw.webp',
            'ghostfacesw2.webp',
            'ghostfacesw3.webp',
            'jacksw.webp',
            'jasonsw.webp',
            'jasonsw2.webp',
            'jasonsw3.webp',
            'leatherfacesw.webp',
            'leatherfacesw2.webp',
            'leatherfacesw3.webp',
            'leprechaunsw.webp',
            'loupgarousw.webp',
            'momiesw.webp',
            'myerssw.webp',
            'myerssw2.webp',
            'myerssw3.webp',
            'operasw.webp',
            'pinheadsw.webp',
            'pinheadsw2.webp',
            'pinheadsw3.webp',
            'regansw.webp',
            'regansw2.webp',
            'regansw3.webp',
            'robotsw.webp',
            'robotsw2.webp',
            'sawsw.webp',
            'sawsw2.webp',
            'sawsw3.webp',
            'zombiesw.webp',
            'zombiesw2.webp',
            'zombiesw3.webp',
            'halloween001.webp',
            'halloween002.webp',
            'halloween003.webp',
            'halloween004.webp',
            'halloween005.webp',
            'halloween006.webp',
            'halloween007.webp'
        ],
        Noel: [
            'noel001.webp',
            'noel002.webp',
            'noel003.webp',
            'noel004.webp',
            'noel005.webp',
            'noel006.webp',
            'noel007.webp',
            'noel008.webp',
            'noel009.webp',
            'noel010.webp',
            'noel011.webp',
            'noel012.webp',
            'noel013.webp',
            'noel014.webp',
            'noel015.webp',
            'noel016.webp',
            'noel017.webp',
            'noel018.webp',
            'noel019.webp',
            'noel020.webp',
            'noel021.webp',
            'noel022.webp',
            'noel023.webp',
            'noel024.webp',
            'noel025.webp',
            'noel026.webp',
            'noel027.webp',
            'noel028.webp',
            'noel029.webp',
            'noel030.webp',
            'noel031.webp',
            'noel032.webp',
            'noel033.webp',
            'noel034.webp',
            'noel035.webp',
            'noel036.webp',
            'noel037.webp',
            'noel038.webp',
            'noel039.webp',
            'noel040.webp',
            'noel041.webp',
            'noel042.webp',
            'noel043.webp',
            'noel044.webp',
            'noel045.webp',
            'noel046.webp',
            'noel047.webp',
            'noel048.webp',
            'noel049.webp',
            'noel050.webp',
            'noel051.webp'
        ],
        NouvelAn: [
            'nouvelan001.webp',
            'nouvelan002.webp',
            'nouvelan003.webp',
            'nouvelan004.webp',
            'nouvelan005.webp',
            'nouvelan006.webp',
            'nouvelan007.webp',
            'nouvelan008.webp',
            'nouvelan009.webp',
            'nouvelan010.webp',
            'nouvelan011.webp',
            'nouvelan012.webp',
            'nouvelan013.webp',
            'nouvelan014.webp',
            'nouvelan015.webp',
            'nouvelan016.webp',
            'nouvelan017.webp',
            'nouvelan018.webp',
            'nouvelan019.webp',
            'nouvelan020.webp'
        ],
    };

    useEffect(() => {
        const today = new Date();
        const todayStr = getFormattedDate(today);

        // Trouver la catégorie actuelle
        const currentCategory = categories.find((category) => {
            const startDate = parseDate(category.start_date);
            const endDate = parseDate(category.end_date);

            if (endDate < startDate) {
                // Gérer les périodes chevauchantes comme Noel (par exemple, 12-26 à 01-05)
                return todayStr >= startDate || todayStr <= endDate;
            }
            return todayStr >= startDate && todayStr <= endDate;
        }) || { name: 'Permanentes' }; // Par défaut, afficher "Permanentes"

        // Sélectionner une image aléatoire pour la catégorie actuelle
        const images = heroImages[currentCategory.name];
        if (images && images.length > 0) {
            const randomImage = images[Math.floor(Math.random() * images.length)];
            setHeroImage({ image_url: randomImage, categoryName: currentCategory.name });
        }
    }, []);

    // Formater une date (sans l'année)
    const getFormattedDate = (date) => {
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${month}-${day}`;
    };

    // Analyser une date (sans l'année)
    const parseDate = (dateStr) => {
        const [month, day] = dateStr.split('-').map(Number);
        return `${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    };

    return (
        <div className="hero-section">
            {heroImage ? (
                <div
                    className="hero-image-container"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/images/${heroImage.categoryName}/${heroImage.image_url})`,
                    }}
                >
                    <h1>Bienvenue sur Starz Webradio</h1>
                    <p>Écoutez vos stations préférées en direct, gratuitement</p>
                    <a href="#radios" className="hero-link">Découvrir les stations</a>
                </div>
            ) : (
                <p className="hero-placeholder">Aucune image disponible pour aujourd'hui.</p>
            )}
        </div>
    );
}

export default Hero;