import React, { useState, useEffect, useRef } from "react";
import "./WebRadioPlayer.css";
import 'bootstrap-icons/font/bootstrap-icons.css';

function WebRadioPlayer({ selectedStation, setSelectedStation, setPlayingStation }) {
    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(100);
    const [currentTime, setCurrentTime] = useState("");
    const [songInfo, setSongInfo] = useState({ artist: "Bienvenue", title: "Choisissez une station" });
    const [albumCover, setAlbumCover] = useState(`${process.env.PUBLIC_URL}/logos/sw.webp`);
    const [history, setHistory] = useState([]);

    const artistRef = useRef(null); // Pour le player
    const titleRef = useRef(null); // Pour le player
    const audioRef = useRef(new Audio());
    const [isArtistScrolling, setIsArtistScrolling] = useState(false);
    const [isTitleScrolling, setIsTitleScrolling] = useState(false);

    let songInterval = useRef(null);
    let historyInterval = useRef(null);

    // Fonction pour récupérer les informations de la chanson actuelle avec AllOrigins
    // const fetchCurrentSong = async () => {
    //     if (!selectedStation) return;
    //     const sid = selectedStation.historyUrl.split("sid=")[1];
    //     const url = `http://62.210.204.2:8000/stats?sid=${sid}`;
    //     const cacheBuster = new Date().getTime();

    //     try {
    //         const response = await fetch(`https://api.allorigins.win/get?url=${encodeURIComponent(url)}&cacheBuster=${cacheBuster}`);
    //         const data = await response.json();
    //         const parser = new DOMParser();
    //         const xmlDoc = parser.parseFromString(data.contents, "text/xml");

    //         const songTitle = xmlDoc.getElementsByTagName("SONGTITLE")[0]?.textContent || "Titre non disponible";
    //         const [artist, title] = songTitle.split(" - ");
    //         setSongInfo({ artist: artist || "Artiste inconnu", title: title || "Titre inconnu" });

    //         fetchArtistImage(artist);
    //     } catch (err) {
    //         console.error("Erreur lors de la récupération des données:", err);
    //     }
    // };

    // Avec Starz Webradio
    const fetchCurrentSong = async () => {
        if (!selectedStation) return;
        const sid = selectedStation.historyUrl.split("sid=")[1];
        const url = `https://audio.starzwebradio.com/stats?sid=${sid}`;
        const cacheBuster = new Date().getTime();

        try {
            // Requête directe sans proxy, avec le cacheBuster dans l’URL
            const response = await fetch(`${url}?cacheBuster=${cacheBuster}`);
            const data = await response.text();  // Reçoit les données en texte pour XML
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(data, "text/xml");

            const songTitle = xmlDoc.getElementsByTagName("SONGTITLE")[0]?.textContent || "Titre non disponible";
            const [artist, title] = songTitle.split(" - ");
            setSongInfo({ artist: artist || "Artiste inconnu", title: title || "Titre inconnu" });

            fetchArtistImage(artist);
        } catch (err) {
            console.error("Erreur lors de la récupération des données:", err);
        }
    };

    const fetchArtistImage = async (artist) => {
        const clientId = "ebabe7602d634ebeb50f092f72660c55";
        const clientSecret = "f0b362f99e6244538a584f92af504488";

        const tokenResponse = await fetch("https://accounts.spotify.com/api/token", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Basic " + btoa(`${clientId}:${clientSecret}`),
            },
            body: "grant_type=client_credentials"
        });

        const tokenData = await tokenResponse.json();
        const accessToken = tokenData.access_token;

        try {
            const artistResponse = await fetch(`https://api.spotify.com/v1/search?q=${encodeURIComponent(artist)}&type=artist`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                }
            });
            const artistData = await artistResponse.json();
            const artistInfo = artistData.artists.items[0];

            if (artistInfo && artistInfo.images.length > 0) {
                setAlbumCover(artistInfo.images[0].url);
            } else {
                setAlbumCover(`${process.env.PUBLIC_URL}/logos/sw.webp`);
            }
        } catch (err) {
            console.error("Erreur lors de la récupération de l'image de l'artiste:", err);
            setAlbumCover(`${process.env.PUBLIC_URL}/logos/sw.webp`);
        }
    };

    // Avec AllOrigins
    // const fetchHistory = async () => {
    //     if (!selectedStation?.historyUrl) return;
    //     try {
    //         const response = await fetch(`https://api.allorigins.win/get?url=${encodeURIComponent(selectedStation.historyUrl)}`);
    //         const data = await response.json();
    //         const parser = new DOMParser();
    //         const htmlDoc = parser.parseFromString(data.contents, "text/html");
    //         const rows = htmlDoc.querySelectorAll("tr");

    //         const newHistory = Array.from(rows).slice(1).map(row => {
    //             const columns = row.querySelectorAll("td");
    //             return {
    //                 time: columns[0]?.textContent.trim() || '',
    //                 title: columns[1]?.textContent.trim() || '',
    //             };
    //         }).filter(item => item.time && item.title);

    //         setHistory(newHistory);
    //     } catch (err) {
    //         console.error("Erreur lors de la récupération de l'historique:", err);
    //     }
    // };

    // Avec Starz Webradio
    const fetchHistory = async () => {
        if (!selectedStation?.historyUrl) return;

        const sid = selectedStation.historyUrl.split("sid=")[1];

        try {
            const response = await fetch(`https://audio.starzwebradio.com/played.html?sid=${sid}`);
            const data = await response.text();

            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(data, "text/html");
            const rows = htmlDoc.querySelectorAll("tr");

            const newHistory = Array.from(rows).slice(1).map((row) => {
                const columns = row.querySelectorAll("td");
                return {
                    time: columns[0]?.textContent.trim() || '',
                    artist: columns[1]?.textContent.split(" - ")[0]?.trim() || "Artiste inconnu",
                    title: columns[1]?.textContent.split(" - ")[1]?.trim() || "Titre inconnu",
                };
            }).filter((item) =>
                item.time && // Exclut les entrées sans heure
                item.title !== "Song Title" && // Exclut les titres génériques
                !item.artist.startsWith("Status") // Exclut les lignes contenant "Status"
            );

            setHistory(newHistory);
        } catch (err) {
            console.error("Erreur lors de la récupération de l'historique :", err);
        }
    };

    useEffect(() => {
        if (selectedStation) {
            audioRef.current.src = selectedStation.stream;
            setPlaying(true);
            audioRef.current.play();

            fetchCurrentSong();
            fetchHistory();

            songInterval.current = setInterval(fetchCurrentSong, 30000);
            historyInterval.current = setInterval(fetchHistory, 45000);
        }

        return () => {
            clearInterval(songInterval.current);
            clearInterval(historyInterval.current);
        };
    }, [selectedStation]);

    // Fonction pour mettre à jour l'heure toutes les secondes
    useEffect(() => {
        const updateClock = () => {
            const now = new Date();
            const hours = now.getHours().toString().padStart(2, '0'); // Ajoute un zéro devant si nécessaire
            const minutes = now.getMinutes().toString().padStart(2, '0');
            setCurrentTime(`${hours}:${minutes}`);
        };

        updateClock(); // Initial call to set time immediately
        const clockInterval = setInterval(updateClock, 1000);

        return () => clearInterval(clockInterval);
    }, []);

    // const togglePlayPause = () => {
    //     if (playing) {
    //         audioRef.current.pause();
    //         clearInterval(songInterval.current);
    //         clearInterval(historyInterval.current);
    //         resetPlayer();
    //         setSelectedStation(null);
    //         setPlayingStation(null); // Retire le halo
    //     } else {
    //         audioRef.current.play();
    //         fetchCurrentSong();
    //         fetchHistory();
    //         songInterval.current = setInterval(fetchCurrentSong, 30000);
    //         historyInterval.current = setInterval(fetchHistory, 45000);
    //         setPlayingStation(selectedStation.name); // Ajoute le halo
    //     }
    //     setPlaying(!playing); // Bascule l'état de lecture
    // };

    const togglePlayPause = () => {
        if (!selectedStation) {
            console.warn("Aucune station sélectionnée. Sélectionnez une station avant de lire.");
            return; // Sort de la fonction si aucune station n'est sélectionnée
        }

        if (playing) {
            audioRef.current.pause();
            clearInterval(songInterval.current);
            clearInterval(historyInterval.current);
            resetPlayer();
            setSelectedStation(null);
            setPlayingStation(null); // Retire le halo
        } else {
            audioRef.current.play();
            fetchCurrentSong();
            fetchHistory();
            songInterval.current = setInterval(fetchCurrentSong, 30000);
            historyInterval.current = setInterval(fetchHistory, 45000);
            setPlayingStation(selectedStation.name); // Ajoute le halo
        }
        setPlaying(!playing); // Bascule l'état de lecture
    };

    const resetPlayer = () => {
        setSongInfo({ artist: "Bienvenue", title: "Choisissez une station" });
        setAlbumCover(`${process.env.PUBLIC_URL}/logos/sw.webp`);
        setHistory([]);
    };

    const handleVolumeChange = (e) => {
        const volumeValue = e.target.value;
        audioRef.current.volume = volumeValue / 100;
        setVolume(volumeValue);
    };

    // Report ecran vérouillé
    useEffect(() => {
        if (selectedStation && 'mediaSession' in navigator) {
            const artworkType = albumCover.endsWith('.webp') ? 'image/webp' : 'image/jpeg';

            navigator.mediaSession.metadata = new MediaMetadata({
                title: songInfo.title || 'Titre inconnu',
                artist: songInfo.artist || 'Artiste inconnu',
                album: selectedStation.name || 'Station inconnue',
                artwork: [
                    { src: albumCover, sizes: '96x96', type: artworkType },
                    { src: albumCover, sizes: '128x128', type: artworkType },
                    { src: albumCover, sizes: '192x192', type: artworkType },
                    { src: albumCover, sizes: '256x256', type: artworkType },
                ],
            });

            // Gère les actions lecture/pause
            navigator.mediaSession.setActionHandler('play', () => {
                audioRef.current.play();
                setPlaying(true);
            });

            navigator.mediaSession.setActionHandler('pause', () => {
                audioRef.current.pause();
                setPlaying(false);
            });
        }
    }, [selectedStation, songInfo, albumCover]);

    // Gestion scroll Artiste/Titre Player
    useEffect(() => {
        if (artistRef.current && titleRef.current) {
            const artistOverflow =
                artistRef.current.scrollWidth > artistRef.current.clientWidth;
            const titleOverflow =
                titleRef.current.scrollWidth > titleRef.current.clientWidth;

            setIsArtistScrolling(artistOverflow);
            setIsTitleScrolling(titleOverflow);
        }
    }, [songInfo]);

    return (

        <div id="webradio-player-history">
            {/* Section Pochette */}
            <div id="album-cover-container">
                <img id="album-cover" src={albumCover} alt="Pochette de l'album" className={playing ? "active" : ""} />
            </div>
            {/* Section Artiste - Titre */}
            <div className="station-info">
                <div className="station-name">{selectedStation?.name || "Starz Webradio"}</div>
                {/* <div id="current-song">
                    <span id="artist-name">{songInfo.artist}</span>
                    <span id="song-title">{songInfo.title}</span>
                </div> */}
                <div id="current-song">
                    {/* Artiste */}
                    <div
                        id="artist-name"
                        style={{ width: "100%", overflow: "hidden" }}
                        ref={artistRef}
                    >
                        <span
                            className={isArtistScrolling ? "scrolling-text" : ""}
                        >
                            {songInfo.artist}
                        </span>
                    </div>

                    {/* Titre */}
                    <div
                        id="song-title"
                        style={{ width: "100%", overflow: "hidden" }}
                        ref={titleRef}
                    >
                        <span
                            className={isTitleScrolling ? "scrolling-text" : ""}
                        >
                            {songInfo.title}
                        </span>
                    </div>
                </div>
                <div id="play-pause" style={{ width: "60px", height: "60px" }}>
                    {playing ? (
                        <div className="circle-button stop" onClick={togglePlayPause}>
                            <i className="bi bi-stop-fill"></i>
                        </div>
                    ) : (
                        <div className="circle-placeholder"></div>
                    )}
                </div>
                <div id="volume-control">
                    <i id="volume-icon" className="bi bi-volume-up"></i>
                    <input
                        type="range"
                        id="volume-slider"
                        min="0"
                        max="100"
                        value={volume}
                        onChange={handleVolumeChange}
                    />
                    <span id="volume-percentage">{volume}%</span>
                </div>
                <div className="sound-controls">
                    <div className="sound-bars">
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                    <div className="current-time">{currentTime}</div>
                    <div className="icon-live">
                        <i className="bi bi-broadcast-pin"></i> Live
                    </div>
                </div>
            </div>
            {/* Section Historique */}
            <div id="history-container">
                <div id="history-title">
                    <h4>Historique des titres</h4>
                    <hr />
                </div>
                <div id="history">
                    {history.length === 0 ? (
                        <div style={{ textAlign: 'center', margin: '20px 0' }}>
                            <p style={{ color: 'white' }}>Découvrez vos morceaux préférés <br /> en cliquant sur une station !</p>
                            <img src={`${process.env.PUBLIC_URL}/cookie/cookie.png`} alt="Cookie" className="footer-cookie" style={{ width: '150px', height: 'auto', marginTop: '20px' }} />
                        </div>
                    ) : (
                        <div id="history-cards">
                            {history
                                .filter((entry) => entry.artist !== "Artiste inconnu" && entry.title !== "Titre inconnu")
                                .map((entry, index) => (
                                    <div key={index} className="history-card">
                                        <div className="card-time">{entry.time}</div>
                                        <div className="card-artist">{entry.artist}</div>
                                        <div className="card-title">{entry.title}</div>
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            </div>

        </div >



    );
}

export default WebRadioPlayer;